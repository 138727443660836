<template>
    <ClientOnly>
        <div
            class="fixed top-0 left-0 w-full h-screen z-50 pointer-events-none"
            :class="$store.state.dev.isGridOverlayVisible ? 'block' : 'hidden'"
        >
            <div class="container h-full grid grid-cols-4 sm:grid-cols-10 gap-4 mx-auto">
                <div></div>
                <div class="hidden sm:block"></div>
                <div class="hidden sm:block"></div>
                <div></div>
                <div class="hidden sm:block"></div>
                <div class="hidden sm:block"></div>
                <div></div>
                <div class="hidden sm:block"></div>
                <div class="hidden sm:block"></div>
                <div></div>
            </div>
        </div>
    </ClientOnly>
</template>

<script>
export default {
    name: 'GridOverlay',
};
</script>

<style scoped>
.container div {
    height: 100%;
    background-color: rgba(255, 0, 0, 0.1);
}
</style>
