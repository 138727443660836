export const state = () => ({
    scrollY: 0,
    isPageBgDark: false,
});

export const mutations = {
    setScrollY(state, payload) {
        state.scrollY = payload;
    },
    setIsPageBgDark(state, payload) {
        state.isPageBgDark = payload;
    },
};
