<template>
    <div
        class="min-h-screen flex flex-col justify-between"
    >
        <div class="container grid grid-cols-12 gap-5 py-24">
            <div class="col-span-10 col-start-2 md:col-span-4 md:col-start-8 flex items-center justify-center">
                <div class="md:text-left text-center">
                    <h1 class="mb-8 lg:mb-12">{{ error.statusCode }}</h1>
                    <p v-if="error.statusCode === 404">Page not found</p>
                    <p v-else-if="error.message">{{ error.message }}</p>
                    <p v-else>An error occurred</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        error: {
            type: Object,
            required: true,
        },
    },
};
</script>

<style scoped>
p {
    @apply text-lg lg:text-4xl;
}
</style>
