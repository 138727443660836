<template>
    <div id="__layout__content" :class="{ loaded }">
        <Nuxt />
        <GridOverlay />
    </div>
</template>

<script>
import GridOverlay from '@/components/Layout/GridOverlay';

export default {
    name: 'Default',
    components: {
        GridOverlay,
    },
    data() {
        return {
            loaded: false,
            requestId: null,
            vh: 0,
        };
    },
    mounted() {
        this.vh = window.innerHeight;

        setTimeout(() => {
            this.loaded = true;
        }, 10);

        // this.scrollOptions = {
        //     maskElement: document.getElementById('mask'),
        //     rememberingHoudiniElement: document.getElementById('rememberingHoudini'),
        //     ease: 0.05, // <= scroll speed
        //     endY: 0,
        //     y: 0,
        //     resizeRequest: 1,
        //     scrollRequest: 0,
        // };
        //
        // if (this.$device.isDesktop) {
        //     this.handleDesktopScroll();
        //     window.addEventListener('resize', this.onDesktopResize);
        //     document.addEventListener('scroll', this.onDesktopScroll);
        // } else {
        //     this.handleMobileScroll();
        //     document.addEventListener('scroll', this.handleMobileScroll);
        //     document.addEventListener('resize', this.handleMobileScroll);
        // }
    },
    methods: {
        // handleMobileScroll() {
        //     // this.scrollOptions.target.style.transform = `translateY(-${this.scrollOptions.y}px)`;
        //     // if (this.$store.state.globals.isRememberingHoudiniBlockVisible) {
        //     const { top, height } = this.scrollOptions.rememberingHoudiniElement.getBoundingClientRect();
        //     if (top > 0) {
        //         this.scrollOptions.maskElement.style.transform = `translateY(calc(100% - ${
        //             (this.vh - top - height) * 2
        //         }px))`;
        //     } else {
        //         this.scrollOptions.maskElement.style.transform = `translateY(0)`;
        //     }
        //
        //     // }
        // },
        // handleDesktopScroll() {
        //     const resized = this.scrollOptions.resizeRequest > 0;
        //
        //     if (resized) {
        //         this.scrollOptions.resizeRequest = 0;
        //     }
        //
        //     const scrollY = this.scrollOptions.rememberingHoudiniElement.getBoundingClientRect().y;
        //
        //     this.scrollOptions.endY = scrollY;
        //     this.scrollOptions.y += (scrollY - this.scrollOptions.y) * this.scrollOptions.ease;
        //
        //     if (Math.abs(scrollY - this.scrollOptions.y) < 0.05 || resized) {
        //         this.scrollOptions.y = scrollY;
        //         this.scrollOptions.scrollRequest = 0;
        //     }
        //
        //     if (
        //         this.scrollOptions.rememberingHoudiniElement.getBoundingClientRect().top +
        //             this.scrollOptions.rememberingHoudiniElement.getBoundingClientRect().height <
        //         0
        //     ) {
        //         console.log('do nothing');
        //     } else {
        //         this.scrollOptions.maskElement.style.transform = `translateY(calc(100% - ${
        //             (this.vh / 1.5 - this.scrollOptions.y) * 2
        //         }px))`;
        //     }
        //
        //     this.requestId =
        //         this.scrollOptions.scrollRequest > 0 ? requestAnimationFrame(this.handleDesktopScroll) : null;
        // },
        // onDesktopScroll() {
        //     this.scrollOptions.scrollRequest++;
        //     if (!this.requestId) {
        //         this.requestId = requestAnimationFrame(this.handleDesktopScroll);
        //     }
        // },
        // onDesktopResize() {
        //     this.scrollOptions.resizeRequest++;
        //     if (!this.requestId) {
        //         this.requestId = requestAnimationFrame(this.handleDesktopScroll);
        //     }
        // },
    },
};
</script>

<style></style>
