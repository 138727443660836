export default ({ app, $config }) => {
    // Add meta tags
    const meta = [];

    meta.push({
        hid: 'og:image',
        property: 'og:image',
        content: `${$config.baseURL}/og-image.jpg`,
    });

    meta.push({
        hid: 'og:image:url',
        property: 'og:image:url',
        content: `${$config.baseURL}/og-image.jpg`,
    });

    meta.push({
        property: 'og:image:type',
        content: 'image/jpg',
    });

    meta.push({
        property: 'og:url',
        content: $config.baseURL,
    });

    meta.push({
        name: 'robots',
        content: $config.nuxtENV === 'production' ? 'all' : 'noindex, nofollow',
    });

    app.head.meta.push(...meta);
};
