export default ({ store, $device }) => {
    const toggleGrid = (e) => {
        // To make development easier and your sitebuild more consistent with the design, there is a 12 col grid
        // you can turn on that appears over the layout. You can only enable it in development mode. Use it, it's free!
        if (e.ctrlKey && e.code === 'KeyM') {
            store.commit('dev/setIsGridOverlayVisible', !store.state.dev.isGridOverlayVisible);
        }
    };

    if (process.env.NODE_ENV === 'development') {
        window.addEventListener('keyup', toggleGrid);
    }

    // Add some helper classes for styling
    if ($device.isDesktop) {
        document.body.classList.add('desktop');
    }

    if ($device.isMobileOrTablet) {
        document.body.classList.add('mobile-tablet');
    }

    if ($device.isSafari) {
        document.body.classList.add('safari');
    }
};
